import React, { createContext, useContext, useReducer, useEffect } from "react";
import { useRouter } from "next/router";
import AuthContext from "context/Auth";
import StoreService from "services/store";
import storeReducer, {
  initialState,
  onFetchInit,
  onFetchSuccess,
  onFetchFailure,
} from "store/genericFetch";
import { asyncAwaitWrap } from "utils/helpers";
import { generateSubdomainFromLocale } from "utils/app";
import { Loader } from "components";
import { setUserLang } from "utils/axios";

const contextState = { store: null };
const StoreContext = createContext(contextState);
const { Provider, Consumer } = StoreContext;

const StoreProvider = ({ children }) => {
  const router = useRouter();
  const locale = router?.locale;
  const [store, dispatch] = useReducer(storeReducer, initialState);
  const { auth } = useContext(AuthContext);
  const fetchStore = async () => {
    setUserLang(locale);
    dispatch(onFetchInit());
    const subdomain = generateSubdomainFromLocale(locale);
    const [storeApiError, storeApiResponse] = await asyncAwaitWrap(
      StoreService.getStoreBySubdomain(subdomain)
    );
    if (storeApiError || !storeApiResponse.data.count) {
      dispatch(onFetchFailure(storeApiError?.data));
      return;
    }
    const { data: storeObject } = storeApiResponse;
    dispatch(onFetchSuccess(storeObject?.results[0]));
  };
  useEffect(() => {
    if (auth) {
      fetchStore();
    }
  }, [auth, locale]);

  return (
    <React.Fragment>
      {store.isFetching && <Loader />}
      <Provider value={{ store }}>{children}</Provider>
    </React.Fragment>
  );
};

export { StoreProvider, Consumer as StoreConsumer };
export default StoreContext;
