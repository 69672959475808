import React, { useContext, useState, useEffect } from "react";
import Link from "next/link";
import ReactFlagsSelect from "react-flags-select";
import { useRouter } from "next/router";
import StoreContext from "context/Store";
import useLanguageDirection from "hooks/useDir";
import { splitBySepratorAndCreateAnArray } from "utils/helpers";
import { allowedCountries } from "utils/app";

const Nav = () => {
  const router = useRouter();
  const { locales, locale, pathname, asPath, query } = router;
  const [country, setCountry] = useState("BH"); //this has to be fetched based on IP or default to AE
  const [languageCode, setLangaugeCode] = useState("");
  const [languageText, setLangaugeText] = useState("");
  const { store: activeStore } = useContext(StoreContext);
  const store = activeStore?.data;
  const storeNameCaseLower = store?.subdomain?.toLowerCase();
  const countriesEnabled = allowedCountries.map((country) =>
    country.toUpperCase()
  );
  const isMultiStoreCountrySupport =
    Array.isArray(countriesEnabled) && countriesEnabled.length > 1;
  useLanguageDirection();

  useEffect(() => {
    if (!languageCode) {
      return;
    }
    languageCode === "ar"
      ? setLangaugeText("English")
      : setLangaugeText("عربي");
  }, [languageCode]);

  useEffect(() => {
    const languageCodeFromLocale = splitBySepratorAndCreateAnArray(locale, "-");
    setLangaugeCode(languageCodeFromLocale?.[0]);
    setCountry(languageCodeFromLocale?.[1]?.toUpperCase());
  }, [locale]);

  const changeLanguage = () => {
    const countryFromLocale = splitBySepratorAndCreateAnArray(locale, "-")?.[1];
    const selectedLanguage = languageCode === "ar" ? "en" : "ar";
    if (!countryFromLocale && !selectedLanguage) {
      return;
    }
    router.push({ pathname, query }, asPath, {
      locale: `${selectedLanguage}-${countryFromLocale.toUpperCase()}`,
    });
  };

  return (
    <React.Fragment>
      <div className="fixed inset-x-0 top-0 z-3 max-w-5xl mx-auto">
        <nav className="main-nav relative block px-6 py-6 md:px-8 flex items-center">
          <div className="flex-grow">
            <Link href="/" className="inline-block">
              <h1 className="brand">{store?.name}</h1>
            </Link>
          </div>
          <div className="flex-none">
            <div className="flex items-center">
              {locales && locales.length > 1 ? (
                <div>
                  <button
                    onClick={changeLanguage}
                    className="font-bold focus:outline-none language-switch"
                  >
                    {languageText}
                  </button>
                </div>
              ) : null}

              {isMultiStoreCountrySupport && (
                <div>
                  <ReactFlagsSelect
                    id="country-switch"
                    className="country-switch"
                    selectButtonClassName="country-switch-btn"
                    countries={countriesEnabled}
                    customLabels={{
                      AE: { primary: "UAE" },
                      BH: { primary: "BHR" },
                      SA: { primary: "KSA" },
                      KW: { primary: "KWT" },
                    }}
                    selected={country}
                    onSelect={(CountryCode) => {
                      setCountry(CountryCode);
                      router.push("/", "/", {
                        locale: `${languageCode}-${CountryCode}`,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
      <style jsx>{`
        .brand {
          background: url("https://media.zbooni.shop/assets/web/enterprise/${storeNameCaseLower}/logo-${locale}.svg")
            center center no-repeat;
          background-size: 142px;
          text-indent: -999em;
          width: 142px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default Nav;
