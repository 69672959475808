import React from "react";
import { Waypoint } from "react-waypoint";
import isEqual from "lodash/isEqual";
import storeService from "services/store";
import { defaultParamsGetProducts } from "services/requestParamDefaults";
import { Item, Loader } from "components";
import { setDefaultAuthHeader } from "utils/axios";
import { authTokenStatus } from "utils/auth";

class Items extends React.Component {
  constructor(props) {
    super(props);
    const { store, products } = this.props;
    this.initialState = {
      store,
      productsUrl: products,
      products: [],
      nextURL: null,
      isProductFeching: true,
    };
    this.state = { ...this.initialState };
    this.getProducts = this.getProducts.bind(this);
    this.onProductFetchSuccess = this.onProductFetchSuccess.bind(this);
    this.handleWaypointEnter = this.handleWaypointEnter.bind(this);
    this.renderWaypoint = this.renderWaypoint.bind(this);
  }

  getProducts(url, params) {
    this.setState({
      isProductFeching: true,
    });
    return storeService.getProductsByURL(url, params).then((products) => {
      return products;
    });
  }

  onProductFetchSuccess(currentState = this.initialState, productApiResponse) {
    const newProductSet = [
      ...currentState.products,
      ...productApiResponse.results,
    ];
    this.setState({
      products: newProductSet,
      isProductFeching: false,
      nextURL: productApiResponse.next,
    });
  }

  renderWaypoint() {
    const { isProductFeching } = this.state;
    if (isProductFeching) {
      return (
        <span className="text-center text-secondary block p-4">Loading...</span>
      );
    }
    return (
      <Waypoint
        threshold={2.0}
        onEnter={this.handleWaypointEnter}
        fireOnRapidScroll={true}
      />
    );
  }

  handleWaypointEnter() {
    const { nextURL } = this.state;
    if (nextURL) {
      this.getProducts(nextURL, {}).then(({ data }) => {
        this.onProductFetchSuccess(this.state, data);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const isPropsEqual = isEqual(prevProps, this.props);
    if (isPropsEqual) {
      return;
    }

    const activeStore = this.props?.store;
    const productsUrl = this.props?.products;

    if (activeStore && productsUrl) {
      this.getProducts(productsUrl, {
        ...defaultParamsGetProducts,
        ...this.props.additionalQueryParam,
      }).then(({ data }) => {
        this.setState({
          store: activeStore,
          products: data.results,
          isProductFeching: false,
          nextURL: data.next,
        });
      });
    }
  }

  componentDidMount() {
    const currentAuthTokenStatus = authTokenStatus();
    if (currentAuthTokenStatus?.isValid) {
      setDefaultAuthHeader(currentAuthTokenStatus?.token);
      const { productsUrl } = this.state;
      this.getProducts(productsUrl, {
        ...defaultParamsGetProducts,
        ...this.props.additionalQueryParam,
      }).then(({ data }) => {
        this.onProductFetchSuccess(this.state, data);
      });
    }
  }

  render() {
    const { isProductFeching, products, store } = this.state;
    return (
      <React.Fragment>
        <section className="pb-4">
          {products && products.length ? (
            <div className="flex flex-wrap px-2 sm:px-4">
              {products.map((product) => (
                <Item key={product.url} store={store} product={product} />
              ))}
              {this.renderWaypoint()}
            </div>
          ) : (
            <React.Fragment>
              {isProductFeching ? (
                <Loader />
              ) : (
                <span className="text-center block font-bold text-secondary text-xl">
                  Product not found
                </span>
              )}
            </React.Fragment>
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default Items;
