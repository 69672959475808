import axios from "axios";
import StorageService from "utils/storage";
import { API_ENDPOINT_BASE } from "config/constants";
import { splitBySepratorAndCreateAnArray } from "utils/helpers";

export const setBaseUrl = () => {
  axios.defaults.baseURL = API_ENDPOINT_BASE;
};

export const setDefaultAuthHeader = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const setUserLang = (lang) => {
  const userLanguage = splitBySepratorAndCreateAnArray(lang, "-")?.[0];
  let languageHeader = "en-US,en;q=0.5";
  switch (userLanguage) {
    case "ar":
      languageHeader = "ar;q=0.9, en;q=0.8, *;q=0.5";
      break;

    default:
      languageHeader;
      break;
  }
  axios.defaults.headers.common["Accept-Language"] = languageHeader;
  StorageService.setToStorage("Session", "ACCEPT_LANGUAGE", languageHeader);
};

export const axiosCustom = axios.create();
