export const APP_BASE = process.env.NEXT_PUBLIC_APP_BASE;
export const ZBNI_WEB_BASE = process.env.NEXT_PUBLIC_ZBNI_WEB_BASE;
export const API_ENDPOINT_BASE = process.env.NEXT_PUBLIC_API_ENDPOINT_BASE;
export const CLIENT_ID = process.env.NEXT_PUBLIC_CLIENT_ID;
export const CLIENT_SECRET = process.env.NEXT_PUBLIC_CLIENT_SECRET;
export const GRANT_TYPE = "client_credentials";
export const AUTH_URL = `${API_ENDPOINT_BASE}/oauth/token/`;
export const THUMBNAILER_URL = `${API_ENDPOINT_BASE}/image/`;
export const IS_THUMBNAILER_ENABELED =
  process.env.NEXT_PUBLIC_IS_THUMBNAILER_ENABELED;
export const GA_ID = process.env.NEXT_PUBLIC_GA_ID;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const APP_SESSION_EXPIRY_IN_SECONDS =
  process.env.NEXT_PUBLIC_SESSION_EXPIRY_IN_SECONDS;
export const ZBNI_STORE_SUBDOMAIN =
  process.env.NEXT_PUBLIC_ZBNI_STORE_SUBDOMAIN;
export const ZBNI_STORE_SUBDOMAIN_BASE =
  process.env.NEXT_PUBLIC_ZBNI_STORE_SUBDOMAIN_BASE;
export const ZBNI_STORES_ALLOWED_COUNTRIES =
  process.env.NEXT_PUBLIC_ZBNI_STORES_ALLOWED_COUNTRIES;
export const DEFAULT_STORE_LOCATION =
  process.env.NEXT_PUBLIC_DEFAULT_STORE_LOCATION;
export const DEFAULT_STORE_LOCATION_IP =
  process.env.NEXT_PUBLIC_DEFAULT_STORE_LOCATION_IP;
export const CUSTOMER_SUPPORT_URL =
  process.env.NEXT_PUBLIC_CUSTOMER_SUPPORT_URL;
