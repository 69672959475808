const isoCountries = {
  AE: {
    name: "United Arab Emirates",
    alpha2: "AE",
    alpha3: "UAE",
  },
  SA: {
    name: "Saudi Arabia",
    alpha2: "SA",
    alpha3: "KSA",
  },
  KW: {
    name: "Kuwait",
    alpha2: "KW",
    alpha3: "KWT",
  },
  BH: {
    name: "Bahrain",
    alpha2: "BH",
    alpha3: "BHR",
  },
};

export const alpha2toAlpha3 = (code) => {
  return isoCountries[code].alpha3;
};
