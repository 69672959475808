import img from "public/placeholder/3x2.png";
const PlaceholderItem = () => {
  return (
    <React.Fragment>
      <img className="w-16 h-16 rounded mr-2 object-cover" src={img} />
      <div className="flex flex-col flex-grow justify-between text-sm">
        <h6 className="bg-gray-200 h-2 max-w-three-fourth"></h6>
        <div className="details">
          <span className="flex bg-gray-200 h-2 max-w-one-fourth"></span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlaceholderItem;
