import React, { useContext } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import StoreContext from "context/Store";
import classNames from "classnames";

const CategoryNav = () => {
  const { t: trans } = useTranslation("common");
  const router = useRouter();
  const {
    query: { category: activeCategory },
  } = router;
  const StoreContextValue = useContext(StoreContext);
  const store = StoreContextValue?.store?.data ?? {};
  const storeTags = store.tag_list ?? [];
  return (
    <React.Fragment>
      <nav className="px-4 py-4 text-center">
        <ul className="link-items m-auto">
          {storeTags.map((storeTag) => {
            return (
              <li
                className="link-item inline-block text-sm px-2 font-bold"
                key={storeTag.id}
              >
                <Link
                  href="/[category]"
                  as={`/${storeTag.slug}`}
                  className={classNames(
                    storeTag.slug === activeCategory
                      ? "nav-link underline text-secondary"
                      : "nav-link underline text-gray-800 font-semibold"
                  )}
                >
                  {trans([storeTag.name], { [storeTag.name]: storeTag.name })}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <style jsx>{`
        .link-items {
          width: 90%;
        }
        .link-item a::after {
          content: " >";
        }
      `}</style>
    </React.Fragment>
  );
};

export default CategoryNav;
