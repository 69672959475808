import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { isRtlLang } from "utils/rtlLanguageDetector";

const useLanguageDirection = (locale) => {
  const router = useRouter();
  locale = router?.locale ?? locale;
  const isLocaleRTL = isRtlLang(locale);
  const [languageDirection, setLanguageDirection] = useState("ltr");

  useEffect(() => {
    if (isLocaleRTL) {
      setLanguageDirection("rtl");
      document.documentElement.dir = "rtl";
      return;
    }
    document.documentElement.dir = "ltr";
  }, [locale, isLocaleRTL]);

  return { languageDirection, setLanguageDirection };
};

export default useLanguageDirection;
