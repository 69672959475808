import React from "react";
import Head from "next/head";
import { stripHtml } from "string-strip-html";
import { truncateLongString } from "utils/helpers";

const setDescription = (store, basket = {}, product = {}, page) => {
  switch (page) {
    case "basket":
      const labelItem = basket.total_quantity === 1 ? "Item" : "Items";
      const basketTotalwithFixedDecimals = parseFloat(basket.total).toFixed(2);
      return `Click to checkout: (${basket.total_quantity}) ${labelItem} -- Total ${basketTotalwithFixedDecimals} ${basket.total_currency}`;
      break;
    case "product":
      const descriptionText = product?.description
        ? truncateLongString(stripHtml(product.description)?.result, 60)
        : "";
      return descriptionText;
      break;
    default:
      return store.description;
      break;
  }
};

const setTitle = (store = {}, product = {}, page) => {
  switch (page) {
    case "product":
      return product.name;
      break;
    default:
      return store.name;
      break;
  }
};

const setImage = (store = {}, product = {}, page) => {
  switch (page) {
    case "product":
      return product?.assets?.[0]?.asset;
      break;
    default:
      return store.logo;
      break;
  }
};

const OGMetaDataHead = ({ origin, page, store, basket, product }) => {
  return (
    <Head>
      <title>{setTitle(store, product, page)}</title>
      <meta property="og:site_name" content={store.name} key="site_name" />
      <meta
        property="og:title"
        content={setTitle(store, product, page)}
        key="og_title"
      />
      <meta property="og:url" content={origin} key="og_url" />
      <meta property="og:type" content="website" key="og_type" />
      <meta
        property="og:image"
        content={setImage(store, product, page)}
        key="og_image"
      />
      <meta property="og:image:width" content="880" key="og_image_width" />
      <meta property="og:image:height" content="500" key="og_image_height" />
      <meta itemProp="name" content={store.name} key="item_prop_name" />
      <meta itemProp="url" content={origin} key="item_prop_url" />
      <meta
        itemProp="thumbnailUrl"
        content={setImage(store, product, page)}
        key="item_prop_thumbnail_url"
      />
      <meta
        itemProp="image"
        content={setImage(store, product, page)}
        key="item_prop_image"
      />
      <meta
        name="twitter:title"
        content={setTitle(store, product, page)}
        key="twitter_title"
      />
      <meta
        name="twitter:image"
        content={setImage(store, product, page)}
        key="twitter_image"
      />
      <meta name="twitter:url" content={origin} key="twitter_url" />
      <meta name="twitter:card" content="summary" key="twitter_card" />
      <meta
        name="twitter:description"
        content={setDescription(store, basket, product, page)}
        key="twitter_card"
      />
      <meta
        name="description"
        content={setDescription(store, basket, product, page)}
        key="description"
      />
    </Head>
  );
};

export default OGMetaDataHead;
