import React from "react";
import { useTranslation } from "next-i18next";
import { alpha2toAlpha3 } from "utils/isoCountries";

const HeroSubText = ({ store }) => {
  const { t: trans } = useTranslation("common");
  return (
    <div className="px-4 pt-8 pb-4">
      <div className="leading-snug text-center text-sm md:text-base">
        <p className="px-4">
          {trans(
            "Located in City Centre Bahrain Mall, Saks Fifth Avenue is one of the world's leading specialty retailers and the only luxury department store in Bahrain and Eastern Saudi Arabia. Renowned for our excellent designer collections across Women's, Men's, Kids' and beauty, with over 300+ international and regional brands, our expertly edited assortment, first-rate fashion expertise and exemplary client service are what we pride ourselves on."
          )}
        </p>
        <p className="py-2">
          {trans(
            "Fostering deep connections with our customers, we are proudly the community's beating heart."
          )}
        </p>
      </div>
    </div>
  );
};

export default HeroSubText;
