import React from "react";

const Fence = ({ title, description }) => (
  <div
    role="alert"
    className="flex flex-col justify-center h-screen mx-auto max-w-sm text-center"
  >
    <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
      {title}
    </div>
    <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
      <p>{description}</p>
    </div>
  </div>
);

export default Fence;
