import { Footer, Nav } from "components";
import classNames from "classnames";

const Layout = (props) => {
  return (
    <div className={classNames("page-container", props.containerClass)}>
      <Nav />
      {props.children}
      <Footer />
    </div>
  );
};
export default Layout;
