import sanitizeHtml from "sanitize-html";

export const replaceSpacesWithHypens = (str) => {
  if (str && str.length) {
    return str.trim().toLowerCase().split(" ").join("-");
  }
};

export const setParams = (defaultParams, overrides) => {
  return {
    ...defaultParams,
    ...overrides,
  };
};

/* dangerouslySetInnerHTML */
export const createMarkup = (data) => {
  return { __html: sanitizeHtml(data) };
};

/*
Async/Await Error Handling 
https://twitter.com/lukeed05/status/1093227112805191681
*/
export const asyncAwaitWrap = (fn, ...args) => {
  let p = typeof fn === "function" ? fn.apply(fn, args) : fn;
  return Promise.resolve(p)
    .then((x) => [undefined, x])
    .catch((err) => [err, undefined]);
};

/*
 * ToDo's: localization
 * (123).toLocaleString("ar-AE", { style: "currency", currency: "AED" });
 */
export const priceWithCurrency = (currencyCode, value, decimalPosition = 2) => {
  const sanitizedVal = parseFloat(value)
    .toFixed(decimalPosition)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  return `${currencyCode} ${sanitizedVal}`;
};

export const pricetoFixedDecimal = (value, decimalPosition = 2) => {
  const formattedVal = parseFloat(value)
    .toFixed(decimalPosition)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  return formattedVal;
};

export const toPercentage = (value) => {
  const formattedVal = Math.floor(value * 100);
  return `${formattedVal}%`;
};

/**
 * A more accurate way to round with decimals
 * http://www.jacklmoore.com/notes/rounding-in-javascript/
 */
export const roundToFixedDecimal = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

export const draftHttpStatus = (status) => {
  return {
    isIdle: status === "idle",
    isPending: status === "pending",
    isResolved: status === "resolved",
    isRejected: status === "rejected",
  };
};

/**
 * Truncate a long string and add ellipsis
 * after the specified number of characters.
 */
export const truncateLongString = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

/**
 * Split a comma seprated sentence and remove surrounding spaces
 * mostly used to handle env vars.
 * eg: NEXT_PUBLIC_ZBNI_STORE_SUBDOMAIN='museedit,museedit-sa,museedit-kw'
 */
export const splitBySepratorAndCreateAnArray = (str, seperator = ",") => {
  if (!str || typeof str !== "string") {
    return;
  }
  return str.split(seperator).map((item) => item.trim().toLowerCase());
};

/**
 * Slugify strings.
 */
export const convertToSlug = (text) => {
  text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};
