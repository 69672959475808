import axios from "axios";
import * as Constants from "config/constants";

export default {
  getToken: () => {
    return axios({
      method: "POST",
      url: `${Constants.AUTH_URL}`,
      data: {
        client_id: Constants.CLIENT_ID,
        client_secret: Constants.CLIENT_SECRET,
        grant_type: Constants.GRANT_TYPE
      }
    });
  }
};
