// ActionTypes
export const FETCH_INIT = "FETCH_INIT";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAILURE = "FETCH_FAILURE";

// Action Creators
export const onFetchInit = () => {
  return {
    type: FETCH_INIT,
  };
};
export const onFetchSuccess = (payload) => {
  return {
    type: FETCH_SUCCESS,
    payload,
  };
};
export const onFetchFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  };
};

// Reducers
const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isError: false,
        lastUpdated: Date.now(),
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        lastUpdated: Date.now(),
        data: action.payload,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        data: null,
        isFetching: false,
        isError: true,
        errors: action.error,
        lastUpdated: Date.now(),
      };
    default:
      return state;
  }
};
export const initialState = {
  isFetching: false,
  isError: false,
  lastUpdated: Date.now(),
  data: null,
  errors: null,
};
export default reducer;
