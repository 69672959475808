import Vault from "vault.js";
import ExecutionEnvironment from "exenv";

export default {
  getFromStorage: (storageType, key) => {
    if (!ExecutionEnvironment.canUseDOM) {
      return;
    }
    return Vault[storageType].get(key);
  },

  setToStorage: (storageType, key, value, opts = {}) => {
    if (!ExecutionEnvironment.canUseDOM) {
      return;
    }
    return Vault[storageType].set(key, value, opts);
  },

  removeFromStorage: (storageType, key) => {
    if (!ExecutionEnvironment.canUseDOM) {
      return;
    }
    return Vault[storageType].remove(key);
  }
};
