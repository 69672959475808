// Initial State
export const baseState = {
  isFetching: false,
  isError: false,
  lastUpdated: Date.now(),
  data: null,
  errors: null
};

// Action Creators
export const onFetchInit = entity => {
  return {
    type: `FETCH_INIT_${entity}`
  };
};
export const onFetchSuccess = (entity, payload) => {
  return {
    type: `FETCH_SUCCESS_${entity}`,
    payload
  };
};
export const onFetchFailure = (entity, error) => {
  return {
    type: `FETCH_FAILURE_${entity}`,
    error
  };
};

const createApiResponseState = (entity = "") => {
  return (state, action) => {
    switch (action.type) {
      case `FETCH_INIT_${entity}`:
        return {
          ...state,
          isFetching: true,
          isError: false,
          lastUpdated: Date.now()
        };
      case `FETCH_SUCCESS_${entity}`:
        return {
          ...state,
          isFetching: false,
          isError: false,
          lastUpdated: Date.now(),
          data: action.payload
        };
      case `FETCH_FAILURE_${entity}`:
        return {
          ...state,
          isFetching: false,
          isError: true,
          errors: action.error,
          lastUpdated: Date.now()
        };
      default:
        return state;
    }
  };
};

export default createApiResponseState;
