import Link from "next/link";
import { useEffect, useReducer } from "react";
import { useTranslation } from "next-i18next";
import { stripHtml } from "string-strip-html";
import useAvatar from "hooks/useAvatar";
import { asyncAwaitWrap } from "utils/helpers";
import { truncateLongString } from "utils/helpers";
import storeService from "services/store";
import fnCreateApiResponseState, {
  baseState,
  onFetchInit,
  onFetchSuccess,
} from "store/universalFetch";

const Item = (props) => {
  const { t: trans } = useTranslation("common");
  const { store, product: productApiResponse } = props;
  const createApiResponseState = fnCreateApiResponseState("product");
  const [state, dispatch] = useReducer(createApiResponseState, baseState);
  const avatar = useAvatar(productApiResponse);
  const getProduct = async (url) => {
    dispatch(onFetchInit("product"));
    const [ProductError, productResponse] = await asyncAwaitWrap(
      storeService.getProductByURL(url)
    );
    if (productResponse) {
      dispatch(onFetchSuccess("product", productResponse.data));
      return productResponse.data;
    }
    return ProductError;
  };

  useEffect(() => {
    getProduct(productApiResponse.url);
  }, [productApiResponse]);

  const { slug, name, price, price_currency, brand } = state.data || {};
  const nameshortened = name
    ? truncateLongString(stripHtml(name)?.result, 120)
    : "";

  return (
    <div className="flex flex-col px-2 py-4 sm:px-4 sm:py-8 w-1/2 sm:w-1/2">
      <div className="leading-relaxed flex flex-col">
        <div className="relative pb-2/3">
          <Link
            href={`/products/[slug]`}
            as={`/products/${slug}`}
            title="Click to view details"
          >
            <img
              className="h-full w-full object-cover"
              src={avatar.url}
              alt={avatar.name}
            />
          </Link>
        </div>
        <div className="flex">
          <div className="flex-1 min-w-0">
            <h2 className="truncate font-bold mt-1 text-sm md:text-base">
              <Link
                href={`/products/[slug]`}
                as={`/products/${slug}`}
                title="Click to view details"
              >
                {trans(brand, { brand })}
              </Link>
            </h2>
          </div>
        </div>
        <div className="mt-1 text-xs md:text-base leading-tight overflow-hidden">
          <Link
            href={`/products/[slug]`}
            as={`/products/${slug}`}
            title="Click to view details"
          >
            {trans(nameshortened, { nameshortened })}
          </Link>
        </div>
        <div className="pt-1">
          <span className="text-xs md:text-base font-normal">
            {price?.toFixed(0)} {price_currency?.code}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Item;
