import React, { useContext } from "react";
import Image from "next/image";
import StoreContext from "context/Store";

const Footer = () => {
  const { store: activeStore } = useContext(StoreContext);
  const store = activeStore?.data;
  const storeNameCaseLower = store?.subdomain?.toLowerCase();

  return (
    <React.Fragment>
      <div className="footer px-8 py-8 border-t border-gray-200 text-center text-gray-600 text-xs md:text-sm">
        <h1 className="enterprise-branding m-auto">
          <Image
            src={`https://media.zbooni.shop/assets/web/enterprise/${storeNameCaseLower}/logo.svg`}
            alt={`${store?.name} logo`}
            width={160}
            height={27}
            className="mx-auto"
          />
        </h1>
        <span className="block pt-2">A Shopping experience powered by</span>
        <h2 className="zbooni-branding mx-auto mt-4">
          <a
            className="block"
            href="https://zbooni.com"
            title="Visit Zbooni Website"
            target="_blank"
          >
            <Image
              src={`https://media.zbooni.shop/assets/web/enterprise/zbooni/zbooni-logo-black.svg`}
              alt="Zbooni logo"
              width={91}
              height={20}
              className="mx-auto"
            />
          </a>
        </h2>
        <span className="block pt-2">
          &copy; Copyright {`${new Date().getFullYear()}`} Zbooni, DMCC
        </span>
        <span className="block pb-4">All Rights Reserved</span>
      </div>
    </React.Fragment>
  );
};

export default Footer;
