import StorageUtils from "utils/storage";

export const authTokenStatus = () => {
  const auth = StorageUtils.getFromStorage("Cookie", "__zbnAuth");
  const tokenCreationTimeInMilliseconds = auth?.created_time;
  const tokenExpiresIn = auth?.expires_in * 1000;

  if (!tokenCreationTimeInMilliseconds || !tokenExpiresIn) {
    return { token: null, isValid: false };
  }

  const now = new Date().getTime();
  const expiryGracePeriodInMilliseconds = 300000;
  return {
    token: auth?.access_token,
    isValid:
      now <
      tokenCreationTimeInMilliseconds +
        (tokenExpiresIn - expiryGracePeriodInMilliseconds),
  };
};
