import React, { useContext, useEffect } from "react";
import StoreContext from "context/Store";
import whatsappIcon from "public/icons/icon-whatsApp.svg";

const WhatsAppQuickChat = () => {
  const { store: activeStore } = useContext(StoreContext);
  const store = activeStore?.data;
  const storeName = store?.name ?? null;
  const whatsapp = store?.extra_data?.whatsapp ?? null;
  const introText =
    "Hello, I’ve just browsed your catalog, and would like more information.";
  return (
    <React.Fragment>
      <div className="fixed right-0 bottom-0 z-3">
        <a
          id="direct-chat"
          href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=${introText}`}
          title={`Chat with ${storeName} on WhatsApp`}
          target="_blank"
          className="block whatsapp-cta"
        >
          Contact
        </a>
      </div>
      <style jsx>{`
        .whatsapp-cta {
          background-image: url(${whatsappIcon});
          display: block;
          font-size: 0;
          height: 52px;
          line-height: 0;
          margin: 10px;
          overflow: hidden;
          width: 52px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default WhatsAppQuickChat;
