import * as Constants from "config/constants";
import { splitBySepratorAndCreateAnArray } from "utils/helpers";

export const allowedCountries = splitBySepratorAndCreateAnArray(
  Constants.ZBNI_STORES_ALLOWED_COUNTRIES
);

export const generateSubdomainFromLocale = (locale) => {
  const defaultStoreCountry = "bh";
  const subdomainBase = Constants.ZBNI_STORE_SUBDOMAIN_BASE;
  const countryFromLocale = splitBySepratorAndCreateAnArray(locale, "-")?.[1];
  const currentLocaleHasStore = countryFromLocale
    ? allowedCountries.includes(countryFromLocale)
    : false;
  const subdomain =
    currentLocaleHasStore && countryFromLocale !== defaultStoreCountry
      ? `${subdomainBase}-${countryFromLocale}`
      : `${subdomainBase}`;
  return subdomain;
};

export const getCountryFromLocale = (locale) => {
  const countryFromLocale =
    splitBySepratorAndCreateAnArray(locale, "-")?.[1] ??
    Constants.DEFAULT_STORE_LOCATION;
  return countryFromLocale;
};
