import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import React, { createContext, useEffect, useReducer } from "react";
import { useQuery } from "@tanstack/react-query";

import AuthService from "services/auth";
import StorageUtils from "utils/storage";
import genericFetchReducer, { initialState } from "store/genericFetch";
import { setDefaultAuthHeader } from "utils/axios";
import {
  onFetchInit,
  onFetchSuccess,
  onFetchFailure,
} from "store/genericFetch";

const contextInitialValues = {
  auth: null,
  error: null,
  isLoading: false,
  isError: false,
};

const storeAuthInCookie = (auth) => {
  const authWithCurrentTime = {
    ...auth,
    created_time: new Date().getTime(),
  };
  StorageUtils.setToStorage("Cookie", "__zbnAuth", authWithCurrentTime, {
    expires: `+${auth.expires_in} seconds`,
    path: "/",
    secure: !process.env.NODE_ENV || process.env.NODE_ENV !== "development",
  });
};

const AuthContext = createContext(contextInitialValues);
const { Provider, Consumer } = AuthContext;

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(genericFetchReducer, initialState);
  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useQuery(["authTokenFetch"], () => AuthService.getToken());

  const refreshAuth = async (failedRequest) => {
    dispatch(onFetchInit());
    const { data } = await AuthService.getToken();
    dispatch(onFetchSuccess(data));
    storeAuthInCookie(data);
    setDefaultAuthHeader(data.access_token);
    console.log("Auth refresh");
    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + data.access_token;
    return await Promise.resolve();
  };

  useEffect(() => {
    createAuthRefreshInterceptor(axios, refreshAuth);
  }, []);

  useEffect(() => {
    dispatch(onFetchInit());
  }, [isLoading]);

  useEffect(() => {
    dispatch(onFetchFailure(error));
  }, [error]);

  useEffect(() => {
    const statusSuccess = response?.status === 200;
    if (statusSuccess) {
      dispatch(onFetchSuccess(response.data));
      storeAuthInCookie(response.data);
      setDefaultAuthHeader(response.data.access_token);
    }
  }, [response]);

  return (
    <React.Fragment>
      <Provider
        value={{
          auth: state.data,
          error,
          isLoading,
          isError,
        }}
      >
        {children}
      </Provider>
    </React.Fragment>
  );
};

export { AuthProvider, Consumer as AuthConsumer };
export default AuthContext;
