import { useState, useEffect } from "react";
import { IS_THUMBNAILER_ENABELED, THUMBNAILER_URL } from "config/constants";
import img from "public/placeholder/3x2.png";

const useAvatar = ({ name, assets: itemAsset }) => {
  const [avatar, setAvatar] = useState({
    url: img,
    alt: name
  });

  useEffect(() => {
    if (Array.isArray(itemAsset) && itemAsset.length) {
      const itemAssetByPosition = []
        .concat(itemAsset)
        .sort((a, b) => a.position - b.position);
      const itemPreview = itemAssetByPosition.slice(0, 1).map((asset, key) => {
        return asset.asset;
      });

      if (itemPreview && itemPreview.length) {
        if (IS_THUMBNAILER_ENABELED === "true") {
          setAvatar({
            ...avatar,
            url: `${THUMBNAILER_URL}?src=${itemPreview[0]}&width=640&height=960&upscale=false&extension=jpg`
          });
        } else {
          setAvatar({
            ...avatar,
            url: itemPreview[0]
          });
        }
      }
    }
  }, [itemAsset]);

  return avatar;
};

export default useAvatar;
