import React, { Fragment, useEffect, useRef, useState } from "react";
import { appWithTranslation } from "next-i18next";
import * as Sentry from "@sentry/node";
import { ToastContainer } from "react-toastify";
import { useRouter } from "next/router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import * as Constants from "config/constants";
import { setBaseUrl, setUserLang } from "utils/axios";

import { AuthProvider } from "context/Auth";
import { StoreProvider } from "context/Store";
import "react-toastify/dist/ReactToastify.css";
import "styles/main.css";

Sentry.init({
  dsn: Constants.SENTRY_DSN,
});

const MyApp = ({ Component, pageProps, err }) => {
  const [queryClient] = useState(() => new QueryClient());
  const modifiedPageProps = { ...pageProps, err };
  const router = useRouter();

  useEffect(() => {
    setBaseUrl();
    setUserLang();
  }, []);

  useEffect(() => {
    const locale = router.locale;
    if (!locale) {
      return;
    }
    setUserLang(locale);
  }, [router.locale]);

  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <AuthProvider>
            <StoreProvider>
              <Component {...modifiedPageProps} />
            </StoreProvider>
          </AuthProvider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <ToastContainer />
    </Fragment>
  );
};

export default appWithTranslation(MyApp);
