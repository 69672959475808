import React, { useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import Downshift from "downshift";
import debounce from "lodash.debounce";
import StoreContext from "context/Store";
import storeService from "services/store";
import { defaultParamsGetProducts } from "services/requestParamDefaults";

const ProductSearch = (props) => {
  const router = useRouter();
  const storeCtx = useContext(StoreContext);
  const { t: trans } = useTranslation("common");
  const { store } = storeCtx;
  const [productsApiResponse, setProductsApiResponse] = useState({});
  const [products, setProducts] = useState([]);

  const preventResetOnBlur = (state, changes) => {
    if (changes.type === Downshift.stateChangeTypes.blurInput) {
      return {}; // no-changes
    }
    return changes;
  };

  const fetchProducts = async (store, params = defaultParamsGetProducts) => {
    const productsApiResponse = await storeService.getProductsByURL(
      store.data.products,
      params
    );
    const { data: productsList } = productsApiResponse;
    setProductsApiResponse(productsList);
  };

  const fetchProductsDebounced = debounce(
    (store, params) => fetchProducts(store, params),
    500
  );

  useEffect(() => {
    if (store && store.data && store.data.products) {
      fetchProductsDebounced(store);
    }
  }, [store]);

  useEffect(() => {
    if (productsApiResponse && productsApiResponse.results) {
      setProducts(productsApiResponse.results);
    }
  }, [productsApiResponse]);

  return (
    <Downshift
      id="search-items"
      stateReducer={preventResetOnBlur}
      onChange={(selection) => {
        const { slug } = selection;
        router.push("/products/[slug]", `/products/${slug}`);
      }}
      itemToString={(item) => (item ? item.name : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
        clearSelection,
      }) => (
        <div className="flex relative bg-white input-container-wrap__search">
          <label
            {...getLabelProps({
              className: "hidden invisible",
            })}
          >
            Enter a product or brand
          </label>
          <div
            className={"flex-1 input-container__search"}
            {...getRootProps({}, { suppressRefError: true })}
          >
            <input
              {...getInputProps({
                onChange: (e) => {
                  fetchProductsDebounced(store, {
                    ...defaultParamsGetProducts,
                    search: e.target.value,
                  });
                },
                placeholder: trans("Search for products or brands"),
                className:
                  "product-search-input rounded-none border-b-2 border-gray-900 w-full leading-tight focus:outline-none",
              })}
            />
            {inputValue || isOpen ? (
              <span className="icon-remove" onClick={clearSelection}>
                X
              </span>
            ) : null}
          </div>
          <ul
            {...getMenuProps({
              className: "autocomplete-dropdown-container list-items",
            })}
          >
            {isOpen
              ? products && (
                  <React.Fragment>
                    {products.map((item, index) => (
                      <li
                        {...getItemProps({
                          className: classNames(
                            "list-item border-b border-gray-300 text-sm md:text-base",
                            {
                              "bg-secondary text-white":
                                highlightedIndex === index,
                              "font-semibold": selectedItem === item,
                            }
                          ),
                          key: item.url,
                          index,
                          item,
                        })}
                      >
                        {item.brand} - {item.name}
                      </li>
                    ))}
                  </React.Fragment>
                )
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
};

export default ProductSearch;
