import axios from "axios";
import * as Constants from "config/constants";
import { defaultParamsGetProducts } from "services/requestParamDefaults";

export default {
  createStoreURL: (storeID) => {
    return `${Constants.API_ENDPOINT_BASE}/stores/${storeID}/`;
  },
  getStoreByURL: (storeURL) => {
    return axios({
      method: "GET",
      url: `${storeURL}`,
    });
  },

  getStoreBySubdomain: (subdomain) => {
    return axios({
      method: "GET",
      url: `${Constants.API_ENDPOINT_BASE}/stores/`,
      params: {
        subdomain: `${subdomain}`,
      },
    });
  },
  getProductsByURL: (productURL, params = defaultParamsGetProducts) => {
    return axios({
      method: "GET",
      url: `${productURL}`,
      params: params,
    });
  },
  getProductByURL: (url) => {
    return axios({
      method: "GET",
      url: `${url}`,
    });
  },
  getProductBySlug: (url, slug) => {
    return axios({
      method: "GET",
      url: `${url}`,
      params: {
        slug,
      },
    });
  },
};
